<template>
    <div class="mouse-icon">
        <div class="scroller"></div>
    </div>
</template>

<script>
export default {
    name: 'AnimatingScrollIndicator',
};
</script>

<style scoped>
.mouse-icon {
    width: 3px;
    padding: 10px 15px;
    height: 35px;
    border: 2px solid #fff;
    border-radius: 25px;
    box-sizing: content-box;
    transform: scale(0.75);
}

.scroller {
    @keyframes scroll {
        0% {
            opacity: 0;
        }
        10% {
            transform: translateY(0);
            opacity: 1;
        }
        100% {
            transform: translateY(15px);
            opacity: 0;
        }
    }

    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
    animation-iteration-count: infinite;
}
</style>
