<template>
  <div>
    <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-2 lg:gap-12">
      <AnimatingBrandLogo v-for="(item, i) in dataSet" :key="`logo-${i}`" :delay="item.delay" :logos="item.logos"/>
    </div>
  </div>
</template>

<script>
import AnimatingBrandLogo from './AnimatingBrandLogo.vue';

export default {
  name: 'AnimatingBrandLogos',
  components: {
    AnimatingBrandLogo,
  },
  data() {
    return {
      brandLogoDelays: [0, 0, 2, 2, 4, 4, 8, 8, 10, 10, 12, 12, 14, 14, 16, 16],
      dataSet: [],
    };
  },
  async beforeMount() {
    let data = [];

    await this.$axios
        .$get('/brand-logos', {
          baseURL: '/',
        })
        .then((res) => {
          data = res.data.sort((a, b) => a.name.localeCompare(b.name));
        })
        .catch((err) => {
          console.log(err);
        });

    this.dataSet = data.reduce((acc, curr, i, arr) => {
      if (i % 2 !== 0) {
        let delayIndex;

        if (i === 1) {
          // In here we are setting the logic for the first pair of logos, that was
          // requested to include HBO as a fix element.
          // We also want it to stay for long, so we set its delay manually
          delayIndex = this.brandLogoDelays.length - 1;
        } else {
          delayIndex = Math.floor(Math.random() * this.brandLogoDelays.length);
        }

        const delay = this.brandLogoDelays[delayIndex];
        this.brandLogoDelays.splice(delayIndex, 1);

        acc.push({
          logos: [arr[i], arr[i - 1]],
          delay,
        });
      }

      return acc;
    }, []);
  },
};
</script>

<style scoped></style>
