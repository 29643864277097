<template>
    <span class="inline-block" :aria-label="label">
        <button
            class="text-white rounded w-7 h-7 p-0 inline-flex items-center justify-center transition-colors ml-0.5"
            :class="isPlaying ? 'bg-blue-500' : 'bg-blue-400 hover:bg-blue-450'"
            @click="play"
        >
            <i class="pi pi-volume-up text-sm"></i>
        </button>
        <audio ref="player" class="hidden" @playing="isPlaying = true" @ended="isPlaying = false">
            <source :src="audioSrc" type="audio/mp3" />
        </audio>
    </span>
</template>

<script>
export default {
    name: 'ReadButton',
    props: {
        label: {
            type: String,
            required: true,
        },
        audioSrc: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isPlaying: false,
        };
    },
    methods: {
        play(e) {
            e.preventDefault();
            e.stopPropagation();
            this.$refs.player.play();
        },
    },
};
</script>

<style scoped></style>
