<template>
    <NuxtLink
        class="block relative w-full h-screen overflow-hidden cursor-pointer"
        :class="{ idle: isCursorIdling }"
        :to="`/selected-works/${slug}`"
        @mousemove.native="handleMouseMove"
    >
        <picture>
            <source v-if="coverImageMobileSrc" media="(min-width:992px)" :srcset="coverImageSrc" />
            <img
                :src="coverImageMobileSrc || coverImageSrc"
                :alt="`${title} Cover`"
                class="absolute w-full h-full object-cover z-1"
            />
        </picture>

        <ClientOnly v-if="vimeoUrl && !$store.state.globals.isLoadingScreenVisible">
            <Transition name="fade">
                <div v-show="isLoaded" class="vimeo-wrapper z-2">
                    <vimeo-player
                        ref="player"
                        :video-url="vimeoUrl"
                        :options="vimeoOptions"
                        @loaded="isLoaded = true"
                        @playing="isPlaying = true"
                        @pause="isPlaying = false"
                    />
                </div>
            </Transition>
        </ClientOnly>

        <div
            class="container relative h-full z-3 text-shadow transition-opacity"
            :class="{ 'opacity-0': isCursorIdling }"
        >
            <div class="absolute lg:bottom-1/3 left-0 px-4" :class="$device.isDesktop ? 'bottom-40' : 'bottom-28'">
                <div v-if="brand" class="lg:text-lg" style="color: white">{{ brand }}</div>
                <div class="header-2 uppercase">{{ title }}</div>
            </div>
            <Transition v-if="$device.isDesktop" name="fade">
                <AnimatingScrollIndicator v-if="$store.state.globals.scrollY < 100" class="absolute bottom-24 left-5" />
            </Transition>
        </div>
    </NuxtLink>
</template>

<script>
import { debounce, throttle } from 'throttle-debounce';
import AnimatingScrollIndicator from '../UI/AnimatingScrollIndicator.vue';

export default {
    name: 'MainHero',
    components: {
        AnimatingScrollIndicator,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        slug: {
            type: String,
            required: true,
        },
        coverImageSrc: {
            type: String,
            required: true,
        },
        coverImageMobileSrc: {
            type: String,
            required: false,
            default: null,
        },
        brand: {
            type: String,
            required: false,
            default: null,
        },
        vimeoUrl: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            isLoaded: false,
            isPlaying: false,
            isCursorIdling: false,
            vimeoOptions: {
                background: true,
                autoplay: false,
                playsinline: true,
                loop: true,
                quality: '1080p',
                byline: false,
                title: false,
            },
        };
    },
    watch: {
        '$store.state.globals.isLoadingScreenVisible'(value) {
            if (!value && this.$device.isDesktop) {
                setTimeout(() => {
                    this.isCursorIdling = false;
                    this.activateCursorIdleState();
                }, 2000);
            }
        },
        '$store.state.globals.scrollY'(value) {
            if (!this.$refs.player) {
                return;
            }

            if (value > window.innerHeight && this.isPlaying) {
                this.$refs.player.pause();
            } else if (value <= window.innerHeight && !this.isPlaying) {
                this.$refs.player.play();
            }
        },
    },
    methods: {
        activateCursorIdleState: debounce(3000, false, function () {
            this.isCursorIdling = true;
        }),
        handleMouseMove: throttle(100, false, function () {
            this.isCursorIdling = false;
            this.activateCursorIdleState();
        }),
    },
};
</script>

<style scoped></style>
