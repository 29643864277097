<template>
    <article
        @mouseover="handleMouseOver"
        @mouseout="handleMouseOut"
        @touchstart="handleMouseOver"
        @mousemove="handleMouseMove"
    >
        <NuxtLink
            ref="container"
            :to="`/selected-works/${slug}`"
            class="relative overflow-hidden flex items-center h-[100vw] sm:h-[74vh]"
            :class="{ idle: isCursorIdling }"
        >
            <div
                v-lazy-background :lazy-background="coverImageSrc"
                class="absolute w-full h-[calc(100%+100px)] top-[-50px] left-0 bg-cover bg-center z-2"
                :class="{ 'opacity-0': isVideoStarted && canStartVideo }"
                style="transition: 0.2s opacity ease"
            >
                <div
                    class="absolute w-full h-full top-0 left-0 flex justify-center items-center pointer-events-none"
                    :class="isBufferSpinnerVisible ? 'opacity-1 bg-gray-700/40' : 'opacity-0'"
                    style="transition: 0.2s opacity ease"
                >
                    <PulseLoader color="rgba(255, 255, 255, 1)" size="10px" />
                </div>
            </div>

            <div v-if="isPlayerMounted && vimeoUrl" class="vimeo-wrapper z-1">
                <vimeo-player
                    ref="player"
                    :video-url="vimeoUrl"
                    :options="vimeoOptions"
                    class="flex-grow"
                    @play="onPlay"
                    @pause="onPause"
                    @bufferend="onBufferEnd"
                />
            </div>

            <div
                class="absolute w-full pointer-events-none z-2 bottom-12 text-shadow"
                :class="{
                    'opacity-0': isCursorIdling && isVideoPlaying,
                    'sm:bottom-20 lg:bottom-32': !small,
                    'sm:bottom-20': small,
                }"
                style="transition: 0.3s opacity ease"
            >
                <div :class="small ? 'ml-4 lg:ml-12' : 'container'">
                    <div v-if="brand" class="lg:text-lg">{{ brand }}</div>
                    <div class="uppercase" :class="small ? 'text-2xl lg:text-3xl font-Avenir-Black' : 'header-2'">
                        {{ title }}
                    </div>
                </div>
            </div>
        </NuxtLink>
    </article>
</template>

<script>
// eslint-disable-next-line
import { throttle, debounce } from 'throttle-debounce';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
    name: 'ReferenceCard',
    components: {
        PulseLoader,
    },
    props: {
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
        slug: {
            type: String,
            required: true,
        },
        brand: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        vimeoUrl: {
            type: String,
            required: false,
            default: null,
        },
        coverImageSrc: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isVideoStarted: false,
            isVideoPlaying: false,
            isLoaderAnimationVisible: false,
            isPlayerMounted: false,
            isBufferSpinnerVisible: null,
            canStartVideo: false,
            vimeoOptions: {
                muted: true,
                background: this.$device.isDesktop,
                responsive: true,
                controls: false,
                portrait: true,
                autoplay: false,
                loop: true,
                quality: this.small ? '720p' : '1080p', // will only affect desktop browsers
                byline: false,
                title: false,
            },
            isCursorIdling: false,
            scrollHandler: debounce(
                Math.floor(Math.random() * (100 - 50 + 1) + 50),
                false,
                this.controlPlayerMountedState
            ),
        };
    },
    mounted() {
        document.addEventListener('scroll', this.scrollHandler);
        this.controlPlayerMountedState();
    },
    beforeDestroy() {
        document.removeEventListener('scroll', this.scrollHandler);
    },
    methods: {
        controlPlayerMountedState() {
            if (!this.$refs.container) {
                return;
            }

            const containerTop = this.$refs.container.$el.getBoundingClientRect().top;
            this.isPlayerMounted = containerTop > -500 && containerTop < window.innerHeight + 500;

            if (!this.isPlayerMounted) {
                this.isVideoStarted = false;
                this.canStartVideo = false;
            }
        },
        onPlay() {
            this.isVideoStarted = true;
            this.isVideoPlaying = true;
            this.isLoaderAnimationVisible = false;

            if (!this.canStartVideo) {
                setTimeout(() => {
                    this.$refs.player.pause();
                }, 10);
            }
        },
        onPause() {
            this.isVideoPlaying = false;
        },
        handleMouseOver() {
            this.canStartVideo = true;

            const forcePlay = () => {
                if (!this.$refs.container) {
                    return;
                }

                const containerTop = this.$refs.container.$el.getBoundingClientRect().top;
                const isPlayerInViewport = containerTop > -1500 && containerTop < window.innerHeight + 1500;

                if (this.$refs.player) {
                    if (this.isBufferSpinnerVisible === null) {
                        this.isBufferSpinnerVisible = true;
                    }
                    this.$refs.player.play();
                } else if (isPlayerInViewport) {
                    setTimeout(() => {
                        forcePlay();
                    }, 50);
                }
            };

            forcePlay();

            this.isLoaderAnimationVisible = true;
        },
        handleMouseOut() {
            if (this.$refs.player) {
                this.$refs.player.pause();
            }
        },
        onBufferEnd() {
            this.isBufferSpinnerVisible = false;
        },
        activateCursorIdleState: debounce(2000, false, function () {
            this.isCursorIdling = true;
        }),
        handleMouseMove: throttle(100, false, function () {
            this.isCursorIdling = false;
            this.activateCursorIdleState();
        }),
    },
};
</script>

<style></style>
